/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-mutable-exports */

let _baseURL: string;
export let _onlineParserURL: string;

if (process.env.REACT_APP_CI_COMMIT_REF_NAME === 'main') {
  _baseURL = process.env.REACT_APP_PROD || '';
  _onlineParserURL = process.env.REACT_APP_PROD_ONLINE_PARSER || '';
} else if (process.env.REACT_APP_CI_COMMIT_REF_NAME === 'test') {
  _baseURL = process.env.REACT_APP_TEST || '';
  _onlineParserURL = process.env.REACT_APP_TEST_ONLINE_PARSER || '';
} else if (process.env.REACT_APP_CI_COMMIT_REF_NAME === 'dev' || process.env.REACT_APP_WORK_MODE === 'dev') {
  _baseURL = process.env.REACT_APP_PROD || '';
  _onlineParserURL = process.env.REACT_APP_PROD_ONLINE_PARSER || '';
} else {
  _baseURL = process.env.REACT_APP_PROD || '';
  _onlineParserURL = process.env.REACT_APP_PROD_ONLINE_PARSER || '';
}

export default _baseURL;
